import { Component, OnInit } from '@angular/core';
import { CharacterService } from '../../services/character.service';

@Component({
  selector: 'app-characters',
  templateUrl: './characters.component.html',
  styleUrls: ['./characters.component.css']
})
export class CharactersComponent implements OnInit {

  public characters: Character[];

  constructor(private service: CharacterService) { }

  ngOnInit() {
    this.service.getAllCharacters().subscribe(data => {
      this.characters = data;
    })
  }
}
