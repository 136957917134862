import { Component, OnInit } from '@angular/core';
import { ColorService } from '../../services/color.service';

@Component({
  selector: 'app-colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.css']
})
export class ColorsComponent implements OnInit {

  public colors: Color[];

  constructor(private service: ColorService) { }

  ngOnInit() {
    this.service.getAllColors().subscribe(data => {
      this.colors = data;
    })
  }
}
