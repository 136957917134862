import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JokeService {

  _baseURL: string = "api/Joke";

  constructor(private http: HttpClient) { }

  getAllJokes() {
    return this.http.get<Joke[]>(this._baseURL + "/GetAll");
  }

  upvoteJoke(id: string) {
    console.log("here2" + id);

    return this.http.put(this._baseURL + "/UpdateScore/" + id, null);
  }
}
