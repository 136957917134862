import { Component, OnInit } from '@angular/core';
import { JokeService } from '../../services/joke.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-joke-upvote',
  templateUrl: './joke-upvote.component.html',
  styleUrls: ['./joke-upvote.component.css']
})
export class JokeUpvoteComponent implements OnInit {

  joke: any;

  constructor(private service: JokeService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.service.upvoteJoke(this.route.snapshot.params.id).subscribe(data => {
      console.log("here1");

      this.joke = data;
    })
  }

}
