import { Component, OnInit } from '@angular/core';
import { JokeService } from '../../services/joke.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-jokes',
  templateUrl: './jokes.component.html',
  styleUrls: ['./jokes.component.css']
})
export class JokesComponent implements OnInit {

  public jokes: Joke[];

  constructor(private service: JokeService, public httpClient: HttpClient) { }

  ngOnInit() {
    this.service.getAllJokes().subscribe(data => {
      this.jokes = data;
    })
  }

  upvote(id: string) {
    this.httpClient.put("/api/Joke/UpdateScore/" + id, null, { responseType: 'text' }).subscribe((res) => { });

    let cur = parseInt(document.getElementById("pop_" + id).innerHTML) + 1;
    document.getElementById("pop_" + id).innerHTML = cur.toString();
  }
}
