import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { HomeComponent } from './components/home/home.component';
import { CharactersComponent } from './components/characters/characters.component';
import { CharacterService } from './services/character.service';
import { ColorsComponent } from './components/colors/colors.component';
import { JokesComponent } from './components/jokes/jokes.component';
import { JokeUpvoteComponent } from './components/joke-upvote/joke-upvote.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CharactersComponent,
    ColorsComponent,
    JokesComponent,
    JokeUpvoteComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'characters', component: CharactersComponent },
      { path: 'colors', component:ColorsComponent },
      { path: 'jokes', component: JokesComponent }
      //{ path: 'character/:id', component: CharacterComponent },
    ])
  ],
  providers: [CharacterService],
  bootstrap: [AppComponent]
})
export class AppModule { }
